import { ArrayOfDate } from "../types/global";
import { OptionModel } from "./fields/ISelect";
import { IAffiliate } from "./IAffiliates";
import { IUser } from "./IUsers";

export type IFinance = {
  createdAt: string;
  id: string;
  affiliate: OptionModel<IAffiliate>,
  type: OptionModel<IFinanceType>,
  dateStart: string;
  dateEnd: string;
  spend: number,
  user: IUser,
};

export type IFinanceEdit = IFinanceCreate;

export type IFinanceType = {
  id: string;
  createdAt: string;
  name: string,
}

export type IFinanceCreate = {
  affiliate: OptionModel<IAffiliate>,
  type: OptionModel<IFinanceType>,
  date: ArrayOfDate;
  spend: number,
};

export type IFinanceFilters = {
  affiliate: IAffiliate[],
  type: IFinanceType[],
  spend: number | undefined,
  date: ArrayOfDate;
}

export type IFinanceCreateRequest = {
  id?: IFinance["id"],
  affiliateId: IAffiliate["id"],
  typeId: IFinanceType["id"],
  dateStart: string;
  dateEnd: string;
  spend: number,
}

export type IFinanceFiltersRequest = Partial<IFinanceCreateRequest>;

export const emptyValues: IFinanceCreate = {
  affiliate: null,
  type: null,
  spend: 0,
  date: [null, null]
};

export const emptyFilters: IFinanceFilters = {
  affiliate: [],
  type: [],
  spend: undefined,
  date: [null, null]
};
