import { IAllFields, TypesOfInput } from "../../../models/fields/IField";
import { IAffiliate } from "../../../models/IAffiliates";
import { IFinanceFilters } from "../../../models/IFinances";
import { Params } from "./create-finance";

const filterFields = ({
  asyncAffiliates,
  affiliateOptions,
  asyncFinanceType,
  financeTypeOptions,
}: Params): IAllFields<IFinanceFilters>[] => (
  [
    {
      async: asyncAffiliates,
      label: "Affiliate",
      labelName: "name",
      name: "affiliate",
      options: affiliateOptions,
      placeholder: "Affiliate",
      type: TypesOfInput.SELECT,
      valueName: "id",
      isMulti: true,
      formatOptionLabel: (option: IAffiliate) => <span>{option.name} - {option.affiliate} </span>,
    },
    {
      async: asyncFinanceType,
      label: "Type",
      labelName: "name",
      name: "type",
      options: financeTypeOptions,
      placeholder: "type",
      type: TypesOfInput.SELECT,
      isMulti: true,
      valueName: "id",
    },
    {
      label: "Spend",
      name: "spend",
      placeholder: "spend",
      type: TypesOfInput.TEXT,
    },

    {
      label: "Date",
      name: "date",
      placeholder: "date",
      type: TypesOfInput.DATEPICKER_RANGE,
    },
  ]
);

export default filterFields;
