import { LocalizationOption } from "../components/common/form/formik/LocalizationSelect";
import { TypeOptions } from "../components/common/items/ColorLabel";
import { WeekDays } from "../types/global";
import { weekDayOptions } from "../utils/formats";
import { OptionModel } from "./fields/ISelect";
import { IComment } from "./IComment";
import { ILocalizationShort } from "./ILocalizations";
import { IUser } from "./IUsers";

enum BrandTypeE {
  BROKER = "broker",
  NETWORK = "network",
  BUYING = "buying",
};

enum BrandLanguageE {
  EN = "en",
  RU = "ru",
  UA = "ua",
};

enum BrandServiceE {
  TRAFFIC = "traffic",
  SERM = "serm",
  SITE = "site",
  DOMAIN = "domain",
  REQUESTLY = "requestly",
};

export enum BrandColorE {
  GREEN = "#008000",
  BIRCH = "#D1E231",
  YELLOW = "#FFFF00",
  ORANGE = "#FFA500",
  RED = "#FF0000",
};

export const brandTypeOptions: TypeOptions<BrandTypeE>[] = [
  {
    label: "Broker",
    value: BrandTypeE.BROKER,
  },
  {
    label: "Network",
    value: BrandTypeE.NETWORK,
  },
  {
    label: "Buying",
    value: BrandTypeE.BUYING,
  },
];

export const brandLanguageOptions: TypeOptions<BrandLanguageE>[] = [
  {
    label: "RU",
    value: BrandLanguageE.RU,
  },
  {
    label: "EN",
    value: BrandLanguageE.EN,
  },
  {
    label: "UA",
    value: BrandLanguageE.UA,
  },
];

export const brandServiceOptions: TypeOptions<BrandServiceE>[] = [
  {
    label: "Domain",
    value: BrandServiceE.DOMAIN,
  },
  {
    label: "Requestly",
    value: BrandServiceE.REQUESTLY,
  },
  {
    label: "SERM",
    value: BrandServiceE.SERM,
  },
  {
    label: "Site",
    value: BrandServiceE.SITE,
  },
  {
    label: "Traffic",
    value: BrandServiceE.TRAFFIC,
  },
];

export const brandColorOptions: TypeOptions<BrandColorE>[] = [
  {
    label: "Yellow",
    color: BrandColorE.YELLOW,
    value: BrandColorE.YELLOW,
  },
  {
    label: "Birch",
    color: BrandColorE.BIRCH,
    value: BrandColorE.BIRCH,
  },
  {
    label: "Green",
    color: BrandColorE.GREEN,
    value: BrandColorE.GREEN,
  },
  {
    label: "Orange",
    color: BrandColorE.ORANGE,
    value: BrandColorE.ORANGE,
  },
  {
    label: "Red",
    color: BrandColorE.RED,
    value: BrandColorE.RED,
  },
];

export type IBrand = {
  name: string,
  type: BrandTypeE,
  language: BrandLanguageE,
  services: BrandServiceE[],
  dayReport: WeekDays | null,
  color: BrandColorE | null,
  saleManager: IUser | null,
  affiliateManager: IUser | null,
  localizations: ILocalizationShort[];
  createdAt: string;
  id: string;
  clientUsername: string | null;
  telegramId: string | null;
  comments: IComment[];
};

export type IBrandEdit = IBrandCreate;

export type IBrandCreate = {
  name: string,
  telegramId: string,
  clientUsername: string,
  type: TypeOptions<BrandTypeE>,
  language: TypeOptions<BrandLanguageE>,
  services: TypeOptions<BrandServiceE>[],
  dayReport: OptionModel<TypeOptions<WeekDays>>,
  color: OptionModel<TypeOptions<BrandColorE>>,
  saleManager: OptionModel<IUser>,
  affiliateManager: OptionModel<IUser>,
  localizations: LocalizationOption[];
};

export type IBrandFilters = {
  saleManager: IBrandCreate["saleManager"],
  affiliateManager: IBrandCreate["affiliateManager"],
  localizations: IBrandCreate["localizations"],
  services: IBrandCreate["services"],
  type: OptionModel<IBrandCreate["type"]>[],
  language: OptionModel<IBrandCreate["language"]>[],
  dayReport: OptionModel<IBrandCreate["dayReport"]>[]
}

export type IBrandCreateRequest = {
  id?: IBrand["id"],
  name: string,
  telegramId: string,
  clientUsername: string,
  type: BrandTypeE,
  language: BrandLanguageE,
  services: BrandServiceE[],
  dayReport: WeekDays,
  color: BrandColorE,
  saleManagerId: IUser["id"] | null,
  affiliateManagerId: IUser["id"] | null,
  localizations: LocalizationOption["value"][];
}

export const emptyValues: IBrandCreate = {
  name: "",
  telegramId: "",
  clientUsername: "",
  type: brandTypeOptions[0],
  language: brandLanguageOptions[0],
  services: [],
  dayReport: weekDayOptions[0],
  color: brandColorOptions[0],
  saleManager: null,
  affiliateManager: null,
  localizations: [],
};

export const emptyFilters: IBrandFilters = {
  type: [],
  language: [],
  dayReport: [],
  saleManager: null,
  affiliateManager: null,
  services: [],
  localizations: []
};

export type IBrandFiltersRequest = Partial<{
  "seller.id": IUser["id"] | null,
  "affiliate.id": IUser["id"] | null,
  "localizations.id": string | null,
  type: string | null,
  language: string | null,
  services: string | null,
  dayReport: string | null,
}>
