import React, { ReactNode, useEffect, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAppDispatch, useAppSelector } from "./hooks/redux";

import "react-toastify/dist/ReactToastify.min.css";
import "./assets/styles/all.scss";
import "./assets/styles/custom.scss";
import "./assets/styles/styles.css";

import AdminLayout from "./pages/_layouts/admin-layout";
import AffiliatesPage from "./pages/affiliates/Affiliates";
import CreateAffiliate from "./pages/affiliates/CreateAffiliate";
import EditAffiliate from "./pages/affiliates/EditAffiliate";
import ClientsPage from "./pages/clients/Clients";
import CreateClient from "./pages/clients/CreateClient";
import EditClient from "./pages/clients/EditClient";
import DashboardPage from "./pages/dashboard/Dashboard";
import CreateDictionaryWord from "./pages/dictionaryWords/CreateDictionaryWord";
import DictionaryWordsPage from "./pages/dictionaryWords/DictionaryWords";
import EditDictionaryWord from "./pages/dictionaryWords/EditDictionaryWord";
import CreateToken from "./pages/integrationToken/CreateToken";
import EditToken from "./pages/integrationToken/EditToken";
import CreateIntegration from "./pages/integrations/CreateIntegration";
import EditIntegration from "./pages/integrations/EditIntegration";
import Integrations from "./pages/integrations/Integrations";
import CreateLabel from "./pages/labels/CreateLabel";
import LabelList from "./pages/labels/LabelList";
import CreateTestLead from "./pages/leads/CreateTestLead";
import EditLead from "./pages/leads/EditLead";
import LeadComments from "./pages/leads/LeadComments";
import LeadLogs from "./pages/leads/LeadLogs";
import LeadsPage from "./pages/leads/Leads";
import CreateLocalization from "./pages/localizations/CreateLocalization";
import EditLocalization from "./pages/localizations/EditLocalization";
import LocalizationsPage from "./pages/localizations/Localizations";
import LoginPage from "./pages/login-flow/Login";
import Login2FAPage from "./pages/login-flow/Login2FA";
import CurrentLog from "./pages/logs/CurrentLog";
import Logs from "./pages/logs/Logs";
import CurrentOffer from "./pages/offers/ChangeItem";
import CreateOffer from "./pages/offers/CreateItem";
import OfferList from "./pages/offers/List";
import CurrentReview from "./pages/reviews/ChangeItem";
import CreateReview from "./pages/reviews/CreateItem";
import ReviewList from "./pages/reviews/List";
import CurrentTag from "./pages/tags/ChangeItem";
import CreateTag from "./pages/tags/CreateItem";
import TagList from "./pages/tags/List";
import EditTelegramChat from "./pages/telegramChat/EditTelegramChat";
import TelegramChatsPage from "./pages/telegramChat/TelegramChats";
import CreateTransaction from "./pages/transactions/CreateTransaction";
import TransactionsPage from "./pages/transactions/Transactions";

import ChangeTeam from "./pages/teams/ChangeItem";
import TeamsPage from "./pages/teams/List";

import ChangeUser from "./pages/users/ChangeItem";
import UsersPage from "./pages/users/List";

import CurrentPush from "./pages/pushes/ChangeItem";
import CreatePush from "./pages/pushes/CreateItem";
import PushList from "./pages/pushes/List";

import CurrentStatus from "./pages/statuses/ChangeItem";
import CreateStatus from "./pages/statuses/CreateItem";
import StatusList from "./pages/statuses/List";

import PlayerList from "./pages/players/List";

import ClientsStatistic from "./pages/statistics/ClientsStatistic";
import StatisticsReplacements from "./pages/statistics/replacements/Replacements";
import StatisticsAffiliateReports from "./pages/statistics/reports/AffiliateReports";
import StatisticsClientsReports from "./pages/statistics/reports/ClientsReports";

import Advertisements from "./pages/Advertisements";

import ApplicationsList from "./pages/applications/List";

import { IAuthUser, superRoles } from "./models/IAuth";
import { UserRole } from "./models/IUsers";
import Loading from "./pages/_layouts/Loading";
import CurrentSetting from "./pages/settings/ChangeItem";
import SettingsList from "./pages/settings/List";
import { getCurrentUser } from "./store/thunks/auth";
import CreateFakeStatistics from "./pages/leads/CreateFakeStatistics";

import EditBrand from "./pages/brands/Edit";
import NewBrand from "./pages/brands/New";
import BrandsList from "./pages/brands/List";
import SendLead from "./pages/leads/SendLead";

import NewFinance from "./pages/finances/New";
import FinancesList from "./pages/finances/List";

export const sideBarLinks = [
  {
    name: "Dashboard",
    icon: "real-estate-1",
    path: "/dashboard",
    component: <DashboardPage />,
    roles: [UserRole.AFFILIATE, UserRole.TEAM_LEAD, UserRole.AFFILIATE_MANAGER, UserRole.PM],
  },
  {
    name: "CMS",
    icon: "reading-1",
    roles: [UserRole.TEAM_LEAD, UserRole.AFFILIATE, UserRole.AFFILIATE_MANAGER, UserRole.PM],
    pages: [
      { name: "Buyers", path: "/affiliates", component: <AffiliatesPage />, roles: [UserRole.PM] },
      { name: "Leads", path: "/leads", component: <LeadsPage />, roles: [UserRole.TEAM_LEAD, UserRole.PM, UserRole.AFFILIATE, UserRole.AFFILIATE_MANAGER] },
      { name: "Applications", path: "/applications", component: <ApplicationsList />, roles: [UserRole.TEAM_LEAD, UserRole.AFFILIATE, UserRole.PM] },
      { name: "Clients", path: "/clients", component: <ClientsPage />, roles: [UserRole.AFFILIATE_MANAGER, UserRole.PM] },
      { name: "Localizations", path: "/localizations", component: <LocalizationsPage />, roles: [UserRole.PM] },
      { name: "Transactions", path: "/transactions", component: <TransactionsPage />, roles: [UserRole.PM] },
      { name: "Telegram Chats", path: "/telegram-chats", component: <TelegramChatsPage />, roles: [UserRole.PM] },
      { name: "Dictionary words", path: "/dictionary-words", component: <DictionaryWordsPage />, roles: [UserRole.PM] },
      { name: "Labels", path: "/labels", component: <LabelList />, roles: [UserRole.PM] },
      { name: "Teams", path: "/teams", component: <TeamsPage />, roles: [UserRole.PM] },
      { name: "Users", path: "/users", component: <UsersPage /> },
      { name: "Brands", path: "/brands", component: <BrandsList />, roles: [UserRole.AFFILIATE_MANAGER, UserRole.SALE_MANAGER] },
      { name: "Finances", path: "/finances", component: <FinancesList />, roles: [UserRole.AFFILIATE, UserRole.TEAM_LEAD, UserRole.PM] },
    ],
  },
  {
    name: "PWA",
    icon: "smartphone-1",
    roles: [UserRole.PM],
    pages: [
      { name: "Reviews", path: "/reviews", component: <ReviewList />, roles: [UserRole.PM] },
      { name: "Tags", path: "/tags", component: <TagList />, roles: [UserRole.PM] },
      { name: "Offers", path: "/offers", component: <OfferList />, roles: [UserRole.PM] },
      { name: "Pushes", path: "/pushes", component: <PushList />, roles: [UserRole.PM] },
      { name: "Players", path: "/players", component: <PlayerList />, roles: [UserRole.PM] },
    ],
  },
  {
    name: "Settings",
    icon: "settings-1",
    roles: [UserRole.PM],
    pages: [
      { name: "Logs", path: "/logs", component: <Logs />, roles: [UserRole.PM] },
      { name: "Statuses", path: "/statuses", component: <StatusList />, roles: [UserRole.PM] },
      { name: "Settings", path: "/settings", component: <SettingsList />, roles: [UserRole.PM] },
    ],
  },
  {
    name: "Statistics",
    icon: "sales-1",
    roles: [UserRole.TEAM_LEAD, UserRole.AFFILIATE, UserRole.AFFILIATE_MANAGER, UserRole.PM],
    pages: [
      { name: "Affiliate reports", path: "/affiliate-reports", component: <StatisticsAffiliateReports />, roles: [UserRole.TEAM_LEAD, UserRole.AFFILIATE, UserRole.AFFILIATE_MANAGER, UserRole.PM] },
      { name: "Clients reports", path: "/client-reports", component: <StatisticsClientsReports />, roles: [UserRole.TEAM_LEAD, UserRole.PM] },
      { name: "Replacements", path: "/replacements", component: <StatisticsReplacements />, roles: [UserRole.TEAM_LEAD, UserRole.AFFILIATE_MANAGER, UserRole.PM] },
    ],
  },
];

export type RouteType = {
  path: string;
  component: ReactNode;
  roles?: UserRole[];
};

const App = () => {
  const dispatch = useAppDispatch();
  const { isAuth, auth } = useAppSelector((state) => state.authSlice);

  useEffect(() => {
    if (isAuth) {
      dispatch(getCurrentUser());
    }
  }, [isAuth]);

  const adminRoutes: RouteType[] = [
    ...sideBarLinks.map((item) => (item.pages ? item.pages : item)).flat(),
    // leads
    { path: "/leads/create-test/:id", component: <CreateTestLead />, roles: [UserRole.PM] },
    { path: "/leads/create-test", component: <CreateTestLead />, roles: [UserRole.PM] },
    { path: "/leads/:id", component: <EditLead />, roles: [UserRole.AFFILIATE_MANAGER, UserRole.PM] },
    { path: "/leads/:id/logs", component: <LeadLogs />, roles: [UserRole.AFFILIATE_MANAGER, UserRole.AFFILIATE, UserRole.TEAM_LEAD, UserRole.PM] },
    { path: "/leads/:id/comments", component: <LeadComments />, roles: [UserRole.AFFILIATE_MANAGER, UserRole.PM] },
    { path: "/leads/create-fake-statistics", component: <CreateFakeStatistics /> },
    { path: "/leads/:id/send-lead", component: <SendLead />, roles: [UserRole.TEAM_LEAD] },

    // clients
    { path: "/clients/new", component: <CreateClient />, roles: [UserRole.AFFILIATE_MANAGER, UserRole.PM] },
    { path: "/clients/:id", component: <EditClient />, roles: [UserRole.AFFILIATE_MANAGER, UserRole.PM] },

    // clients integrations
    { path: "/clients/:id/integrations", component: <Integrations />, roles: [UserRole.PM] },
    { path: "/clients/:id/integrations/new", component: <CreateIntegration />, roles: [UserRole.PM] },
    { path: "/clients/:id/integrations/:integrationId", component: <EditIntegration />, roles: [UserRole.PM] },

    // clients token
    { path: "/clients/:id/token/new", component: <CreateToken />, roles: [UserRole.PM] },
    { path: "/clients/:id/token/edit", component: <EditToken />, roles: [UserRole.PM] },

    // integrations
    { path: "/integrations/:integrationId", component: <EditIntegration />, roles: [UserRole.PM] },

    // affiliates
    { path: "/affiliates/new", component: <CreateAffiliate />, roles: [UserRole.PM] },
    { path: "/affiliates/:id", component: <EditAffiliate />, roles: [UserRole.PM] },

    // localizations
    { path: "/localizations/new", component: <CreateLocalization />, roles: [UserRole.PM] },
    { path: "/localizations/:id", component: <EditLocalization />, roles: [UserRole.PM] },

    // dictionary-words
    { path: "/dictionary-words/new", component: <CreateDictionaryWord />, roles: [UserRole.PM] },
    { path: "/dictionary-words/:id", component: <EditDictionaryWord />, roles: [UserRole.PM] },

    // logs
    { path: "/logs/:id", component: <CurrentLog />, roles: [UserRole.PM] },

    // labels
    { path: "/labels/new", component: <CreateLabel />, roles: [UserRole.PM] },
    { path: "/labels/:id", component: <CreateLabel />, roles: [UserRole.PM] },

    // reviews
    { path: "/reviews/new", component: <CreateReview />, roles: [UserRole.PM] },
    { path: "/reviews/:id", component: <CurrentReview />, roles: [UserRole.PM] },

    // tags
    { path: "/tags/new", component: <CreateTag />, roles: [UserRole.PM] },
    { path: "/tags/:id", component: <CurrentTag />, roles: [UserRole.PM] },

    // offers
    { path: "/offers/new", component: <CreateOffer />, roles: [UserRole.PM] },
    { path: "/offers/:id", component: <CurrentOffer />, roles: [UserRole.PM] },

    // pushes
    { path: "/pushes/new", component: <CreatePush />, roles: [UserRole.PM] },
    { path: "/pushes/:id", component: <CurrentPush />, roles: [UserRole.PM] },

    // statuses
    { path: "/statuses/new", component: <CreateStatus />, roles: [UserRole.PM] },
    { path: "/statuses/:id", component: <CurrentStatus />, roles: [UserRole.PM] },

    // teams
    { path: "/teams/new", component: <ChangeTeam />, roles: [UserRole.PM] },
    { path: "/teams/:id", component: <ChangeTeam />, roles: [UserRole.PM] },

    // users
    { path: "/users/new", component: <ChangeUser />, roles: [UserRole.AFFILIATE_MANAGER] },
    { path: "/users/:id", component: <ChangeUser />, roles: [UserRole.AFFILIATE_MANAGER] },

    { path: "/transactions/new", component: <CreateTransaction />, roles: [UserRole.PM] },
    { path: "/telegram-chats/:id", component: <EditTelegramChat />, roles: [UserRole.PM] },

    { path: "/advertisements/:affiliateId", component: <Advertisements />, roles: [UserRole.PM] },

    // statistics
    {
      path: "/affiliate-reports/clients/:clientId",
      component: <ClientsStatistic />,
      roles: [UserRole.TEAM_LEAD, UserRole.AFFILIATE, UserRole.PM],
    },

    // settings
    { path: "/settings/new", component: <CurrentSetting />, roles: [UserRole.PM] },
    { path: "/settings/:id", component: <CurrentSetting />, roles: [UserRole.PM] },

    // brands
    { path: "/brands/new", component: <NewBrand />, roles: [UserRole.AFFILIATE_MANAGER, UserRole.SALE_MANAGER] },
    { path: "/brands/:id", component: <EditBrand />, roles: [UserRole.AFFILIATE_MANAGER, UserRole.SALE_MANAGER] },

    // brands
    { path: "/finances/new", component: <NewFinance />, roles: [UserRole.AFFILIATE, UserRole.TEAM_LEAD, UserRole.PM] },
  ];

  const filteredPagesByRole = useMemo(() => {
    if (!auth.user) return adminRoutes;
    return superRoles.includes(auth.user.roles)
      ? adminRoutes
      : adminRoutes.filter((item) => item.roles?.includes((auth.user as IAuthUser).roles));
  }, [auth]);

  if (isAuth && !filteredPagesByRole.length) {
    return (
      <div className="absolute top-50per left-50per">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Routes>
        {isAuth ? (
          <>
            <Route element={<AdminLayout />}>
              {filteredPagesByRole.map(({ path, component }) => (
                <Route key={path} path={path} element={component} />
              ))}
              {filteredPagesByRole.length && (
                <Route path="*" element={<Navigate to="/leads" replace />} />
              )}
            </Route>
          </>
        ) : (
          <>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/login/2fa" element={<Login2FAPage />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
