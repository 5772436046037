import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import FilterForm from "../../components/common/form/formik/FilterForm";
import FinancesTable from "../../components/finances/FinancesTable";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { financesSlice } from "../../store/reducers/financesSlice";
import { fetchList } from "../../store/thunks/finances";
import { IFinanceFilters, emptyFilters } from "../../models/IFinances";
import filterFields from "../../components/fields/finances/filters-finances";
import { FetchOptionsParams } from "../../types/global";
import { fetchShortAffiliates } from "../../store/thunks/affiliates";
import { fetchTypesList } from "../../store/thunks/finances";

const List = () => {
  const dispatch = useAppDispatch();
  const { affiliatesShort } = useAppSelector((state) => state.affiliatesReducer);
  const { filter, types } = useAppSelector((state) => state.financesSlice);
  const { setFilters, resetFilters } = financesSlice.actions;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchList({}));
    return () => { dispatch(setFilters(emptyFilters)) };
  }, []);

  const handleFilter = (values: IFinanceFilters) => {
    dispatch(setFilters(values));
    return dispatch(fetchList({}));
  };

  const fields = useMemo(() => filterFields({
    affiliateOptions: affiliatesShort.data,
    asyncAffiliates: {
      fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortAffiliates(params)),
      isLoading: affiliatesShort.loading,
    },
    financeTypeOptions: types.data,
    asyncFinanceType: {
      fetchCallback: (params: FetchOptionsParams) => dispatch(fetchTypesList(params)),
      isLoading: types.loading,
    },
  }), [affiliatesShort, types]);

  return (
    <>
      <div className="container-fluid px-lg-4 px-xl-5">
        <PageTitle title={"Finances"} />
        <div className="row">
          <div className="d-flex mb-3">
            <Button variant="primary" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
              {isFilterOpen ? "Close" : "Open"} Filters
            </Button>
            <Link className="btn btn-primary" to="/finances/new">
              Create finance
            </Link>
          </div>
          {isFilterOpen && (
            <FilterForm
              className="mb-3"
              fields={fields}
              handleSubmit={handleFilter}
              handleReset={() => dispatch(resetFilters())}
              initialValues={filter}
            />
          )}
          <section className="mb-5">
            <div className="card">
              <div className="card-body">
                <FinancesTable className="min-w-screen-lg" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default List;
