import React from 'react';
import { IComment } from '../../models/IComment';
import { formatDateFullTime } from '../../utils/formats';
import Icon from '../common/items/Icon';

interface Props {
  comment: IComment;
}

const Comment = ({ comment }: Props) => {
  const { text, createdAt, user } = comment;
  return (
    <div className="card">
      <div className="card-header d-flex align-items-center p-3">
        <Icon name="user-1" class='svg-icon-sm' />
        <p className="fst-normal mb-0 ml-2">{user?.name} {user?.surname}</p>
      </div>
      <div className="card-body p-3">
        <p>{text}</p>
      </div>
      <div className="card-footer p-3">
        {formatDateFullTime(createdAt)}
      </div>
    </div>
  );
}

export default Comment;
