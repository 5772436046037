import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import FilterForm from "../../components/common/form/formik/FilterForm";
import ReviewsTable from "../../components/reviews/ReviewsTable";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { reviewsSlice } from "../../store/reducers/reviewsSlice";
import { fetchList } from "../../store/thunks/review";
import { IReviewFilters, emptyFilters } from "../../models/IReview";
import filterFields from "../../components/fields/filters-reviews";

const List = () => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector((state) => state.reviewsReducer);
  const { setFilters, resetFilters } = reviewsSlice.actions;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchList({}));
    return () => {dispatch(setFilters(emptyFilters))}
  }, []);

  const handleFilter = (values: IReviewFilters) => {
    dispatch(setFilters(values));
    return dispatch(fetchList({}));
  };

  const fields = useMemo(() => filterFields(), []);

  return (
    <>
      <div className="container-fluid px-lg-4 px-xl-5">
        <PageTitle title={"Reviews"} />
        <div className="row">
          <div className="d-flex mb-3 justify-between">
            <div>
              <Button variant="primary" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
                {isFilterOpen ? "Close" : "Open"} Filters
              </Button>
              <Link className="btn btn-primary" to="/reviews/new">
                Create review
              </Link>
            </div>
          </div>
          {isFilterOpen && (
            <FilterForm
              className="mb-3"
              fields={fields}
              handleSubmit={handleFilter}
              handleReset={() => dispatch(resetFilters())}
              initialValues={filter}
            />
          )}
          <section className="mb-5">
            <div className="card">
              <div className="card-body">
                <ReviewsTable className="min-w-screen-lg" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default List;
