import { RequestList } from "../components/common/tables/types";
import { Api, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";
import { IBrandFilters, IBrand, IBrandEdit, IBrandCreateRequest, BrandColorE, IBrandFiltersRequest, IBrandCreate } from "../models/IBrand";
import { joinFiltersArray } from "../utils/formats";
import { ICommentCreate, ICreateCommentRequest } from "../models/IComment";
import { createComment } from "./comments";

const baseURL = "/v1/brands";

export const getList = ({ filter, ...params }: RequestList<IBrandFilters>): Promise<ListRes<IBrand>> => {
  const newFilter: IBrandFiltersRequest = {
    "seller.id": filter.saleManager?.id,
    "affiliate.id": filter.affiliateManager?.id,
    "localizations.id": joinFiltersArray(filter.localizations),
    type: joinFiltersArray(filter.type),
    language: joinFiltersArray(filter.language),
    services: joinFiltersArray(filter.services),
    dayReport: joinFiltersArray(filter.dayReport),
  };
  const body = {
    ...params,
    filter: newFilter
  };
  return Api.post(baseURL + "/list", filterEmpyValues(body)).then(getResponseData);
};

export const changeItem = (data: IBrandCreate | IBrandEdit): Promise<ModelRes<IBrand>> => {
  const body: IBrandCreateRequest = {
    name: data.name,
    clientUsername: data.clientUsername,
    telegramId: data.telegramId,
    type: data.type.value,
    language: data.language.value,
    services: data.services.map(({ value }) => value),
    dayReport: data.dayReport?.value ?? "monday",
    color: data.color?.value ?? BrandColorE.YELLOW,
    saleManagerId: data.saleManager?.id ?? null,
    affiliateManagerId: data.affiliateManager?.id ?? null,
    localizations: data.localizations.map(({ value }) => value),
  };
  if ("id" in data) {
    body.id = data.id as string;
  }
  return Api.post(baseURL, body).then(getResponseData);
};

export const getItem = (id: string): Promise<ModelRes<IBrand>> => {
  return Api.get(`${baseURL}/${id}`).then(getResponseData);
};

export const deleteItem = (id: string) => {
  return Api.delete(`${baseURL}/${id}`);
};

export const createBrandComment = (data: ICommentCreate) => {
  const body: ICreateCommentRequest = {
    ...data,
    entityType: "brand"
  };
  return createComment(body);
};
