import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FilterForm from "../../components/common/form/formik/FilterForm";
import PageTitle from "../../components/common/items/page-title";
import { editTelegramChatFields } from "../../components/fields/edit-telegramChat";
import TelegramChatsTable from "../../components/telegram-chats/TelegramChatsTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { ITelegramChatUpdate, initialTelegramChatFilters } from "../../models/ITelegramChats";
import { telegramChatsSlice } from "../../store/reducers/telegramChatsSlice";
import { fetchShortClients } from "../../store/thunks/clients";
import { fetchTelegramChats } from "../../store/thunks/telegramChats";
import { FetchOptionsParams } from "../../types/global";

const TelegramChats = () => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector((state) => state.telegramChatsReducer);
  const { setFilters } = telegramChatsSlice.actions;
  const { clientsShort } = useAppSelector((state) => state.clientsReducer);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchTelegramChats());
    return () => {dispatch(setFilters(initialTelegramChatFilters))}
  }, []);

  const handleFilter = (values: ITelegramChatUpdate) => {
    dispatch(setFilters(values));
    return dispatch(fetchTelegramChats());
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title={"TelegramChats"} />
      <div className="row">
        <div className="d-flex mb-3">
          <Button variant="primary" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
            {isFilterOpen ? "Close" : "Open"} Filters
          </Button>
        </div>
        {isFilterOpen && (
          <FilterForm
            className="mb-3"
            fields={editTelegramChatFields({
              asyncClients: {
                fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortClients(params)),
                isLoading: clientsShort.loading,
              },
              clientOptions: clientsShort.data,
            })}
            handleSubmit={handleFilter}
            initialValues={filter}
          />
        )}
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <TelegramChatsTable />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TelegramChats;
