import React, { useMemo } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { createFinanceValidation, createFields } from "../../components/fields/finances/create-finance";
import { emptyValues, IFinanceEdit } from "../../models/IFinances";
import { FetchOptionsParams } from "../../types/global";
import { changeItem, fetchTypesList } from "../../store/thunks/finances";
import { fetchShortAffiliates } from "../../store/thunks/affiliates";

const CreateFinance = () => {
  const dispatch = useAppDispatch();
  const { affiliatesShort } = useAppSelector((state) => state.affiliatesReducer);
  const { types } = useAppSelector((state) => state.financesSlice);

  const fields = useMemo(
    () =>
      createFields({
        affiliateOptions: affiliatesShort.data,
        asyncAffiliates: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortAffiliates(params)),
          isLoading: affiliatesShort.loading,
        },
        financeTypeOptions: types.data,
        asyncFinanceType: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchTypesList(params)),
          isLoading: types.loading,
        },
      }),
    [affiliatesShort, types],
  );

  const handleSubmit = (values: IFinanceEdit) => {
    dispatch(changeItem(values));
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/finances" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating finance" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={emptyValues}
                validationSchema={createFinanceValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => <Field key={field.name} {...field} />)}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateFinance;
