import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { brandColorOptions, brandLanguageOptions, brandServiceOptions, brandTypeOptions, emptyValues, IBrandEdit } from "../../models/IBrand";
import { changeItem, deleteItem, fetchItem } from "../../store/thunks/brands";
import { editFields, editBrandValidation } from "../../components/fields/brands/edit-brand";
import { FetchOptionsParams } from "../../types/global";
import { weekDayOptions } from "../../utils/formats";
import { getValuesFromModel } from "../../api/utils";
import useModal from "../../hooks/useModal";
import { fetchList } from "../../store/thunks/users";
import { roleOptions, UserRole } from "../../models/IUsers";
import { ILocalizationShort } from "../../models/ILocalizations";
import CommentsList from "../../components/comments/CommentsList";

const EditBrand = () => {
  const dispatch = useAppDispatch();
  const { currentItem } = useAppSelector((state) => state.brandsSlice);
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState<IBrandEdit>(emptyValues);
  const { list } = useAppSelector((state) => state.usersReducer);

  useEffect(() => {
    if (!id) return;
    dispatch(fetchItem(id));
  }, []);

  const handleDelete = () => {
    if (!currentItem.data) return;
    dispatch(deleteItem(currentItem.data.id));
  };

  const { ModalComp, handleShowModal } = useModal(handleDelete);

  useEffect(() => {
    if (!currentItem.loading && currentItem.data) {
      const {
        type, language, services, dayReport, color, localizations, telegramId, clientUsername,
        ...restData
      } = currentItem.data;
      const data = getValuesFromModel<IBrandEdit>({
        ...restData,
        telegramId: telegramId ?? "",
        clientUsername: clientUsername ?? "",
        type: brandTypeOptions.find(({ value }) => value === type)!,
        language: brandLanguageOptions.find(({ value }) => value === language)!,
        services: brandServiceOptions.filter(({ value }) => services.includes(value))!,
        dayReport: weekDayOptions.find(({ value }) => value === dayReport)!,
        color: brandColorOptions.find(({ value }) => value === color)!,
        localizations: localizations.map(({ id, name }: ILocalizationShort) => ({ label: name, value: id })),
      }, emptyValues);
      setInitialValues(data);
    }
  }, [currentItem]);

  const fields = useMemo(
    () =>
      editFields({
        asyncSaleUsers: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchList({
            ...params,
            filter: {
              roles: roleOptions.find(option => option.value === UserRole.SALE_MANAGER)!
            }
          })),
          isLoading: list.loading,
        },
        saleUserOptions: list.data,
        asyncAffiliateUsers: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchList({
            ...params,
            filter: {
              roles: roleOptions.find(option => option.value === UserRole.AFFILIATE_MANAGER)!
            }
          })),
          isLoading: list.loading,
        },
        affiliateUserOptions: list.data,
      }),
    [list],
  );

  const handleSubmit = (values: IBrandEdit) => {
    dispatch(changeItem({ ...values, id }));
  };

  if (currentItem.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/brands" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
          Delete
        </Button>
        <PageTitle title="Edit brand" />
        <section className="mb-4">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={editBrandValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => <Field key={field.name} {...field} />)}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
        <section>
          <CommentsList
            comments={currentItem?.data?.comments ?? []}
            entityType="brand"
            modelId={id!}
          />
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default EditBrand;
