import { IAllFields } from "../../../models/fields/IField";
import { AsyncObject } from "../../../models/fields/ISelect";
import { IUser } from "../../../models/IUsers";
import { createBrandValidation, createFields } from "./create-brand";
import { IBrandEdit } from "../../../models/IBrand";

export const editBrandValidation = createBrandValidation;

interface Params {
  affiliateUserOptions: IUser[];
  asyncAffiliateUsers: AsyncObject;
  saleUserOptions: IUser[];
  asyncSaleUsers: AsyncObject;
}

export const editFields = (params: Params): IAllFields<IBrandEdit>[] => createFields(params);
