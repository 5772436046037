import * as apis from "../../api/brands-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IBrand, IBrandCreate } from "../../models/IBrand";
import { ListRes } from "../../api/utils";
import { State } from "../reducers/brandsSlice";
import { FetchOptionsParams } from "../../types/global";

export const fetchList = createAsyncThunk<
  ListRes<IBrand>,
  FetchOptionsParams,
  { state: { brandsSlice: State } }
>("brands/fetchList", (params: FetchOptionsParams, { getState }) => {
  const state: State = getState().brandsSlice;

  const options = {
    filter: state.filter,
    page: state.pagination.page,
    take: state.pagination.take,
    searchTerm: params.searchTerm,
  };

  if (!params.searchTerm) {
    delete options.searchTerm;
  }
  return apis.getList(options);
});

export const changeItem = createAsyncThunk("brands/changeItem", (data: IBrandCreate & { id?: IBrand["id"] }) =>
  apis.changeItem(data),
);

export const fetchItem = createAsyncThunk("brands/fetchItem", (id: string) => apis.getItem(id));

export const deleteItem = createAsyncThunk("brands/deleteItem", (id: string) => apis.deleteItem(id));
