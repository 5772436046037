import { IAllFields, TypesOfInput } from "../../../models/fields/IField";
import { brandLanguageOptions, brandServiceOptions, brandTypeOptions, IBrandFilters } from "../../../models/IBrand";
import { weekDayOptions } from "../../../utils/formats";
import { Params } from "./create-brand";

const filterFields = ({
  affiliateUserOptions,
  asyncAffiliateUsers,
  saleUserOptions,
  asyncSaleUsers,
}: Params): IAllFields<IBrandFilters>[] => [
  {
    label: "Localizations",
    name: "localizations",
    type: TypesOfInput.LOCALIZATIONS,
  },
  {
    async: asyncAffiliateUsers,
    labelName: "name",
    label: "Affiliate manager",
    name: "affiliateManager",
    options: affiliateUserOptions,
    type: TypesOfInput.SELECT,
    valueName: "id",
    placeholder: "Affiliate manager",
  },
  {
    async: asyncSaleUsers,
    labelName: "name",
    label: "Sale manager",
    name: "seller",
    options: saleUserOptions,
    type: TypesOfInput.SELECT,
    valueName: "id",
    placeholder: "Sale manager",
  },
  {
    label: "Type",
    name: "type",
    placeholder: "Type",
    options: brandTypeOptions,
    type: TypesOfInput.SELECT,
    isMulti: true,
  },
  {
    label: "Language",
    name: "language",
    placeholder: "Language",
    options: brandLanguageOptions,
    type: TypesOfInput.SELECT,
    isMulti: true,
  },
  {
    label: "Services",
    name: "services",
    placeholder: "Services",
    options: brandServiceOptions,
    type: TypesOfInput.SELECT,
    isMulti: true,
  },
  {
    label: "Day report",
    name: "dayReport",
    placeholder: "Day report",
    options: weekDayOptions,
    type: TypesOfInput.SELECT,
    isMulti: true,
  },
];

export default filterFields;
