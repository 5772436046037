import * as apis from "../../api/finances-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IFinance, IFinanceCreate, IFinanceType } from "../../models/IFinances";
import { ListRes } from "../../api/utils";
import { State } from "../reducers/financesSlice";
import { FetchOptionsParams } from "../../types/global";

export const fetchList = createAsyncThunk<
  ListRes<IFinance>,
  FetchOptionsParams,
  { state: { financesSlice: State } }
>("finances/fetchList", (params: FetchOptionsParams, { getState }) => {
  const state: State = getState().financesSlice;

  const options = {
    filter: state.filter,
    page: state.pagination.page,
    take: state.pagination.take,
    searchTerm: params.searchTerm,
  };

  if (!params.searchTerm) {
    delete options.searchTerm;
  }
  return apis.getList(options);
});

export const changeItem = createAsyncThunk("finances/changeItem", (data: IFinanceCreate & { id?: IFinance["id"] }) =>
  apis.changeItem(data),
);

export const fetchTypesList = createAsyncThunk<
  ListRes<IFinanceType>,
  FetchOptionsParams,
  { state: { financesSlice: State } }
>("finances/fetchTypesList", (params: FetchOptionsParams<FixMeLater>, { getState }) => {
  const state: State = getState().financesSlice;

  const options = {
    filter: {
      name: params.searchTerm!,
    },
    page: state.pagination.page,
    take: state.pagination.take,
  };

  return apis.getTypesList(options);
});
