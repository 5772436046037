import { TypeOptions } from "../components/common/items/ColorLabel";
import { ArrayOfDate, Modify } from "../types/global";
import { IAffiliate } from "./IAffiliates";
import { ClientType, IClient, typeOptions } from "./IClients";
import { ILabel } from "./ILabels";
import { isBooleanOptions } from "./ILead";
import { ILocalization } from "./ILocalizations";
import { SystemsList } from "./IStatus";
import { OptionModel } from "./fields/ISelect";
import { getPrevWeekFromMonday } from "../utils/formats";

export type LeadCount = {
  [key: string]: number;
};

export type StatisticRequestType = "query";
export type TabKeysType = keyof IStatisticAffiliates;

export type IStatisticAffiliatesData = {
  affiliate: string,
  id: string,
  leads: LeadCount,
  name: string,
}
export type IStatisticAffiliates = {
  detail: IStatisticAffiliatesData[],
  location: IStatisticAffiliatesData[],
};

export type IStatisticClients = {
  id: string;
  leads: LeadCount;
  name: string;
  type: ClientType;
};

export type IStatisticDeposits = {
  id: string;
  name: string;
  balance: number;
  price: number;
};

export type StatisticReportsFilters = {
  clients: IClient[];
  clientType?: OptionModel<TypeOptions<ClientType>>[];
  affiliates: IAffiliate[];
  labels: ILabel[];
  sentAt: ArrayOfDate | [Date, Date];
  createdAt: ArrayOfDate | [Date, Date];
  localization: OptionModel<ILocalization>[],
  isValid: TypeOptions<boolean | null>,
  hasDeposit: TypeOptions<boolean | null>,
  isReplace: TypeOptions<boolean | null>,
};

export const filters: StatisticReportsFilters = {
  clients: [],
  affiliates: [],
  labels: [],
  clientType: [],
  sentAt: getPrevWeekFromMonday(),
  createdAt: [null, null],
  localization: [],
  isValid: isBooleanOptions[0],
  hasDeposit: isBooleanOptions[0],
  isReplace: isBooleanOptions[0],
};

export type ClientStatisticReportsFilters = {
  affiliates: IAffiliate[];
  sentAt: ArrayOfDate | [Date, Date];
  isValid: TypeOptions<boolean | null>,
  hasDeposit: TypeOptions<boolean | null>,
  isReplace: TypeOptions<boolean | null>,
};

export const clientFilters: ClientStatisticReportsFilters = {
  affiliates: [],
  sentAt: [null, null],
  isValid: isBooleanOptions[0],
  hasDeposit: isBooleanOptions[0],
  isReplace: isBooleanOptions[0],
};

export type StatisticReportsFiltersRequest = Modify<
  Partial<StatisticReportsFilters>,
  {
    id?: string[];
    clientId?: string[];
    affiliateId: string[];
    "client.label_id"?: string[];
    "client.type"?: string;
    localizationId?: string;
    isValid: boolean | "",
    hasDeposit: boolean | "",
    isReplace: boolean | "",
  }
>;

export type ClientStatisticReportsFiltersSearchParams = {
  sentAt: ArrayOfDate | [Date, Date];
  affiliateId: string[];
  isValid: boolean | "",
  hasDeposit: boolean | "",
  isReplace: boolean | "",
}

export type ClientStatisticReportsFiltersRequest = ClientStatisticReportsFiltersSearchParams & {
  clientId: string;
}

export type StatisticRequest = {
  type: StatisticRequestType;
  filter?: StatisticReportsFiltersRequest | DepositsFiltersRequest | ClientStatisticReportsFiltersRequest;
};

export type DepositsFiltersRequest = {
  id: string[];
};

export const initialFilters: StatisticRequest = {
  type: "query",
};

export type ClientsStatisticResponse = {
  not_valid: number | null
  list: ClientsStatistic,
};

export type ClientsStatistic = {
  [key: string]: ClientsStatisticItem[];
};

export type ClientsStatisticItem = {
  count: number;
  percent: string;
  status: SystemsList;
};

export type LeadsStatistic = {
  id: string;
  email: string;
  createdAt: string;
  depositedAt: string;
  status: SystemsList | null;
};

export type ReplacementsFilters = {
  sentAt: ArrayOfDate | [Date, Date];
  localization: OptionModel<ILocalization>[],
  affiliate: OptionModel<IAffiliate>,
  clientType: OptionModel<TypeOptions<ClientType>>,
}

export type ReplacementsClientsFiltersRequest = Modify<Partial<ReplacementsFilters>, {
  localizationId?: string,
  affiliateId?: string,
  "client.type"?: ClientType,
}>

export const replacementsFilters: ReplacementsFilters = {
  affiliate: null,
  sentAt: getPrevWeekFromMonday(),
  localization: [],
  clientType: typeOptions[1],
};

export type ReplacementsStatisticRequest = {
  type: StatisticRequestType;
  filter?: ReplacementsClientsFiltersRequest;
};

export type IReplacementsStatisticClients = {
  clientId: string,
  leads: number,
  approved: number,
  notApproved: number,
  client: {
    id: string,
    price: number,
    name: string,
  },
  ids: string,
}

export type LeadType = {
  total: number,
  approved: number,
  notApproved: number,
  percent: string,
}

export type IReplacementsStatisticAffiliates = {
  id: string,
  name: string,
  leads: {
    [key: string]: LeadType,
  },
}

export const emptyLead: LeadType = {
  approved: 0,
  notApproved: 0,
  percent: "0",
  total: 0,
};

export type DiagramFilter = {
  filter: {
    sentAt: StatisticReportsFilters["sentAt"],
    affiliateId: string,
  },
  type: StatisticRequestType,
}

export type ValidReplaceDiagram = {
  affiliateId: string,
  notApproved: number,
  approved: number,
}

export type StatusDiagram = {
  affiliateId: string,
  status: string | null,
  total: number,
}

export type AffiliateProfitType = {
  id: string,
  name: string,
  clients: {
    [id: string]: {
      name: string,
      leads: number,
      approved: number,
      price: number,
      summa: string,
    }
  }
}
