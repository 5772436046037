import React, { useMemo } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { createBrandValidation, createFields } from "../../components/fields/brands/create-brand";
import { emptyValues, IBrandEdit } from "../../models/IBrand";
import { FetchOptionsParams } from "../../types/global";
import { changeItem } from "../../store/thunks/brands";
import { fetchList } from "../../store/thunks/users";
import { roleOptions, UserRole } from "../../models/IUsers";

const CreateBrand = () => {
  const dispatch = useAppDispatch();
  const { list } = useAppSelector((state) => state.usersReducer);

  const fields = useMemo(
    () =>
      createFields({
        asyncSaleUsers: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchList({
            ...params,
            filter: {
              roles: roleOptions.find(option => option.value === UserRole.SALE_MANAGER)!
            }
          })),
          isLoading: list.loading,
        },
        saleUserOptions: list.data,
        asyncAffiliateUsers: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchList({
            ...params,
            filter: {
              roles: roleOptions.find(option => option.value === UserRole.AFFILIATE_MANAGER)!
            }
          })),
          isLoading: list.loading,
        },
        affiliateUserOptions: list.data,
      }),
    [list],
  );

  const handleSubmit = (values: IBrandEdit) => {
    dispatch(changeItem(values));
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/brands" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating brand" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={emptyValues}
                validationSchema={createBrandValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => <Field key={field.name} {...field} />)}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateBrand;
