import React, { useState } from 'react';
import { IComment, ICreateCommentRequest } from '../../models/IComment';
import Comment from './Comment';
import CommentForm from './CommentForm';

interface Props {
  comments: IComment[];
  entityType: ICreateCommentRequest['entityType'],
  modelId: ICreateCommentRequest['modelId'],
}

const CommentsList = ({ comments, entityType, modelId }: Props) => {
  const [formComments, setFormComments] = useState<IComment[]>(comments);

  const addCommentToForm = (newComment: IComment) => {
    setFormComments((prevState) => ([newComment, ...prevState]))
  }

  return (
    <div className="card">
      <div className="card-header py-3">
        <p className="card-heading">Comments</p>
      </div>
      <div className="card-body row">
        {formComments.map((comment) => (
          <div className="col-xs-6 col-md-4 mb-4" key={comment.id}>
            <Comment comment={comment} />
          </div>
        ))}
        <div className="col-xs-6 col-md-4 mb-4">
          <CommentForm entityType={entityType} modelId={modelId} addCommentToForm={addCommentToForm} />
        </div>
      </div>
    </div>
  );
}

export default CommentsList;
