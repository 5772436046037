import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import FilterForm from "../../components/common/form/formik/FilterForm";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getSelectedPlayersIds, playersSlice } from "../../store/reducers/playersSlice";
import { IPlayerFilters, emptyFilters } from "../../models/IPlayer";
import { deleteItems, fetchList } from "../../store/thunks/players";
import { filterFields } from "../../components/fields/players";
import PlayersTable from "../../components/players/PlayersTable";
import { fetchList as fetchOfferList } from "../../store/thunks/offer";
import useModal from "../../hooks/useModal";
import { FetchOptionsParams } from "../../types/global";

const List = () => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector((state) => state.playersSlice);
  const { setFilters, resetFilters } = playersSlice.actions;
  const { list: offerList } = useAppSelector((state) => state.offersReducer);
  const selectedPlayers = useAppSelector(getSelectedPlayersIds);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchList());
    return () => {dispatch(setFilters(emptyFilters))}
  }, []);

  const handleDelete = () => {
    dispatch(deleteItems(selectedPlayers));
  };

  const { ModalComp, handleShowModal } = useModal(handleDelete);

  const handleFilter = (values: IPlayerFilters) => {
    dispatch(setFilters(values));
    return dispatch(fetchList());
  };

  const fields = useMemo(
    () =>
      filterFields({
        asyncOffer: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchOfferList(params)),
          isLoading: offerList.loading,
        },
        offerOptions: offerList.data,
      }),
    [offerList],
  );

  return (
    <>
      <div className="container-fluid px-lg-4 px-xl-5">
        <PageTitle title="Players" />
        <div className="row">
          <div className="d-flex mb-3">
            <Button variant="primary" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
              {isFilterOpen ? "Close" : "Open"} Filters
            </Button>
            {!!selectedPlayers.length && (
              <Button className="w-auto" variant="danger" onClick={handleShowModal}>
                Delete
              </Button>
            )}
          </div>
          {isFilterOpen && (
            <FilterForm
              className="mb-3"
              fields={fields}
              handleSubmit={handleFilter}
              handleReset={() => dispatch(resetFilters())}
              initialValues={filter}
            />
          )}
          <section className="mb-5">
            <div className="card">
              <div className="card-body">
                <PlayersTable className="min-w-screen-lg" />
              </div>
            </div>
          </section>
        </div>
      </div>
      {ModalComp}
    </>
  );
};

export default List;
