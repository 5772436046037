import React from "react";
import { Link } from "react-router-dom";
import { IFinance } from "../../models/IFinances";
import { ColumnDef, PaginationType } from "../common/tables/types";
import { formatDateFullTime } from "../../utils/formats";

type IColumn = ColumnDef<IFinance>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefs({ pagination }: Options): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const affiliate: IColumn = {
    Cell: ({ value }) => (
      <Link className="font-semibold" to={`/affiliates/${value?.id}`}>{value?.name}</Link>
    ),
    Header: "Affiliate",
    id: "affiliate",
    accessor: "affiliate",
    width: "140px",
  };

  const type: IColumn = {
    Cell: ({ value }) => <span className="font-semibold">{value?.name}</span>,
    Header: "Type",
    accessor: "type",
    id: "type",
    width: "60px",
  };

  const spend: IColumn = {
    Header: "Spend",
    accessor: "spend",
    id: "spend",
    width: "40px",
  };

  const dateStart: IColumn = {
    Header: "Date start",
    accessor: "dateStart",
    width: "60px",
    id: "dateStart",
  };

  const dateEnd: IColumn = {
    Header: "Date end",
    accessor: "dateEnd",
    width: "60px",
    id: "dateEnd",
  };

  const user: IColumn = {
    Cell: ({ value }) => <span>{value ? value.name : "-"}</span>,
    Header: "Created by",
    accessor: "user",
    width: "60px",
    id: "createdby",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    accessor: "createdAt",
    width: "50px",
    id: "createdAt",
  };

  return [numberIndex, type, affiliate, spend, dateStart, dateEnd, user, createdAt];
}
