import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Button } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { TypesOfInput } from '../../models/fields/IField';
import { fetchShortClients } from '../../store/thunks/clients';
import { FetchOptionsParams } from '../../types/global';
import { fetchLeads, fetchOneLead } from '../../store/thunks/leads';
import Field from '../../components/common/form/formik/Field';
import { sendManualDistribution } from '../../api/leads-api';
import { ILead } from '../../models/ILead';
import { IClient } from '../../models/IClients';
import Loading from '../_layouts/Loading';
import { toast } from 'react-toastify';
import { ILog } from '../../models/ILog';
import { JSONTree } from 'react-json-tree';
import Icon from '../../components/common/items/Icon';
import { handleCopy } from '../../utils/formats';

type FormValuesT = {
  lead: ILead | null,
  client: IClient | null,
  redirect: string | null,
}

const SendLead = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { id: leadId } = useParams();
  const { clientsShort } = useAppSelector((state) => state.clientsReducer);
  const { leads, currentLead } = useAppSelector(state => state.leadsReducer);
  const [log, setLog] = useState<ILog | null>(null);

  useEffect(() => {
    if (!leadId) return;
    dispatch(fetchOneLead(leadId));
  }, [leadId]);

  const fields = [
    {
      async: {
        fetchCallback: (params: FetchOptionsParams) => dispatch(fetchLeads(params)),
        isLoading: leads.loading
      },
      label: "Lead",
      name: "lead",
      options: leads.data,
      placeholder: "Lead",
      type: TypesOfInput.SELECT,
      valueName: "id",
      labelName: "name"
    },
    {
      async: {
        fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortClients(params)),
        isLoading: clientsShort.loading,
      },
      label: "Client",
      name: "client",
      options: clientsShort.data,
      placeholder: "Client",
      type: TypesOfInput.SELECT,
      valueName: "id",
      labelName: "name",
    },
  ]

  const handleSubmit = async (values: FormValuesT, { setFieldValue }: FormikHelpers<FormValuesT>) => {
    const response = await sendManualDistribution({
      lead: values.lead!,
      client: values.client!,
    });

    if (response.status) {
      toast.success("Lead successfully created");
    } else {
      toast.warning("Failed to create lead, try again");
    }
    setLog(response.log);
    setFieldValue('redirect', response.redirect);
  }

  if (currentLead.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex">
          <Link to={`/leads${search}`} className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
        </div>
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik<FormValuesT>
                enableReinitialize
                initialValues={{
                  client: null,
                  redirect: '',
                  lead: currentLead?.data ?? null,
                }}
                validationSchema={Yup.object({
                  client: Yup.object().typeError("Required"),
                  lead: Yup.object().typeError("Required"),
                })}
                onSubmit={handleSubmit}
              >
                {({ values: { redirect } }) => (
                  <Form className="w-100 px-4">
                    <div className="row">
                      {fields.map((field) => (
                        <Field key={field.name} {...field} />
                      ))}
                      {redirect && (
                        <Button
                          className="w-25 m-auto d-block"
                          variant='outline-primary'
                          onClick={() => handleCopy(redirect)}
                        >
                          Copy redirect
                          <Icon name="hidden-file-1" class="svg-icon-sm ml-2" />
                        </Button>
                      )}
                    </div>
                    <Button
                      type="submit"
                      className="form-control w-25 m-auto d-block"
                      variant="primary"
                    >
                      Send
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
        {log && (
          <section className="mb-5">
            <div className="card">
              <div className="card-body">
                <JSONTree
                  data={log}
                  theme={{ base00: "#fff" }}
                  invertTheme={false}
                  hideRoot={true}
                />
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

export default SendLead;
