import { RequestList } from "../components/common/tables/types";
import { Api, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";
import { IFinanceFilters, IFinance, IFinanceEdit, IFinanceCreateRequest, IFinanceFiltersRequest, IFinanceCreate, IFinanceType } from "../models/IFinances";
import { formatDateToBack, joinFiltersArray } from "../utils/formats";

const baseURL = "/v1/finances";

export const getList = ({ filter, ...params }: RequestList<IFinanceFilters>): Promise<ListRes<IFinance>> => {
  const newFilter: IFinanceFiltersRequest = {
    affiliateId: joinFiltersArray(filter?.affiliate),
    typeId: joinFiltersArray(filter?.type),
    spend: filter.spend!,
    dateStart: formatDateToBack(filter.date[0]) ?? "",
    dateEnd: formatDateToBack(filter.date[1]) ?? "",
  };
  const body = {
    ...params,
    filter: newFilter
  };
  return Api.post(baseURL + "/list", filterEmpyValues(body)).then(getResponseData);
};

export const changeItem = (data: IFinanceCreate | IFinanceEdit): Promise<ModelRes<IFinance>> => {
  const body: IFinanceCreateRequest = {
    affiliateId: data?.affiliate?.id ?? "",
    typeId: data?.type?.id ?? "",
    spend: data.spend,
    dateStart: formatDateToBack(data.date[0]) ?? "",
    dateEnd: formatDateToBack(data.date[1]) ?? "",
  };
  if ("id" in data) {
    body.id = data.id as string;
  }
  return Api.post(baseURL, body).then(getResponseData);
};

export const getTypesList = (body: RequestList<{ name: string }>): Promise<ListRes<IFinanceType>> => {
  return Api.post("/v1/finance-types/list", filterEmpyValues(body)).then(getResponseData);
};
