import { ArcElement, Chart, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IClient } from "../../models/IClients";
import { IFilterLead, initialLeadFilters, isBooleanOptions } from "../../models/ILead";
import { ClientStatisticReportsFilters } from "../../models/IStatistics";
import { leadsSlice } from "../../store/reducers/leadsSlice";
import { fetchLeads } from "../../store/thunks/leads";
import Loading from "../_layouts/Loading";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  clientLeads: number;
  filters: ClientStatisticReportsFilters;
  currentClient: IClient | null;
  notValid: number | null;
}

const ConversionChart = ({ clientLeads, filters, currentClient, notValid }: Props) => {
  const dispatch = useAppDispatch();
  const { pagination, leads } = useAppSelector((state) => state.leadsReducer);
  const { setFilters } = leadsSlice.actions;

  useEffect(() => {
    if (!currentClient) return;
    const localFilter: IFilterLead = {
      ...initialLeadFilters,
      client: currentClient ? [currentClient] : [],
      affiliate: filters.affiliates,
      sentAt: filters.sentAt,
      isValid: filters.isValid,
      isReplace: filters.isReplace,
      hasDeposit: isBooleanOptions.find((item) => item.value) ?? isBooleanOptions[0],
    };
    dispatch(setFilters(localFilter));
    dispatch(fetchLeads({}));
  }, [currentClient]);

  if (leads.loading) return <Loading />

  const total = pagination.total ?? 0;
  const totalPersentage = clientLeads ? (total * 100 / clientLeads).toFixed(2) : 0;

  const validReplaceData = {
    labels: ["client leads", "total leads"],
    datasets: [
      {
        label: "Valid replace",
        data: [clientLeads ? clientLeads - total : clientLeads, total],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const plugins = [
    {
      beforeDraw: function (chart: Chart) {
        const width = chart.width,
          height = chart.height - 20,
          ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 200).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        const text = `${clientLeads}/${total}/${totalPersentage}%`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <>
      <Doughnut
        data={validReplaceData}
        options={{
          aspectRatio: 2,
          responsive: true,

          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        plugins={plugins as FixMeLater}
      />
      <div>
        <p>{clientLeads} - leads</p>
        <p>{total} - deposits</p>
        <p>{totalPersentage}% - conversion </p>
        <p className="mb-0">{(notValid ?? 0).toFixed(2)}% - Total not valid </p>
      </div>
    </>
  );
};

export default ConversionChart;
