import React from 'react';
import { IComment, ICreateCommentRequest } from '../../models/IComment';
import { createComment } from '../../api/comments';
import { Formik, Form, FormikHelpers } from 'formik';
import Field from '../common/form/formik/Field';
import { TypesOfInput } from '../../models/fields/IField';
import { Button } from 'react-bootstrap';
import Icon from '../common/items/Icon';

interface Props {
  entityType: ICreateCommentRequest['entityType'],
  modelId: ICreateCommentRequest['modelId'],
  addCommentToForm: (value: IComment) => void;
}

type FormValuesT = {
  text: string,
}

const CommentForm = ({ entityType, modelId, addCommentToForm }: Props) => {
  const handleCreateComment = ({ text }: FormValuesT, formikHelpers: FormikHelpers<FormValuesT>) => {
    if (!text || !entityType || !modelId) return;

    return createComment({ text, entityType, modelId }).then((data) => {
      addCommentToForm(data.model);
      formikHelpers.resetForm();
    })
  }

  return (
    <div className="card">
      <div className="card-body p-3">
        <Formik
          initialValues={{
            text: '',
          }}
          onSubmit={handleCreateComment}
        >
          <Form>
            <Field
              label=""
              name="text"
              type={TypesOfInput.TEXTAREA}
              size={12}
            />
            <Button
              type="submit"
              className="m-auto"
              variant="primary"
            >
              <Icon name='add-1' class='svg-icon-sm' /> Add comment
            </Button>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default CommentForm;
