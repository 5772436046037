import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../../../models/fields/IField";
import { brandColorOptions, brandLanguageOptions, brandServiceOptions, brandTypeOptions, IBrandCreate } from "../../../models/IBrand";
import { weekDayOptions } from "../../../utils/formats";
import { AsyncObject } from "../../../models/fields/ISelect";
import { IUser } from "../../../models/IUsers";

export const createBrandValidation = Yup.object<Record<keyof IBrandCreate, Yup.AnySchema>>({
  name: Yup.string().required(),
  type: Yup.object().typeError("Required"),
  language: Yup.object().typeError("Required"),
  services: Yup.array().of(Yup.object().typeError("Required")).min(1, "At least 1 service"),
  telegramId: Yup.string(),
  clientUsername: Yup.string(),
  dayReport: Yup.object().nullable(),
  color: Yup.object().nullable(),
  saleManager: Yup.object().nullable(),
  affiliateManager: Yup.object().nullable(),
  localizations: Yup.array().of(Yup.object()),
});

export interface Params {
  affiliateUserOptions: IUser[];
  asyncAffiliateUsers: AsyncObject;
  saleUserOptions: IUser[];
  asyncSaleUsers: AsyncObject;
}

export const createFields = ({
  affiliateUserOptions,
  asyncAffiliateUsers,
  saleUserOptions,
  asyncSaleUsers,
}: Params): IAllFields<IBrandCreate>[] => (
  [
    {
      label: "Name",
      name: "name",
      placeholder: "Name",
      type: TypesOfInput.TEXT,
    },
    {
      label: "Client Username",
      name: "clientUsername",
      placeholder: "Client name",
      type: TypesOfInput.TEXT,
    },
    {
      label: "Telegram id",
      name: "telegramId",
      placeholder: "id",
      type: TypesOfInput.TEXT,
    },
    {
      label: "Type",
      name: "type",
      placeholder: "Type",
      options: brandTypeOptions,
      type: TypesOfInput.SELECT,
    },
    {
      label: "Language",
      name: "language",
      placeholder: "Language",
      options: brandLanguageOptions,
      type: TypesOfInput.SELECT,
    },
    {
      label: "Services",
      name: "services",
      placeholder: "Services",
      options: brandServiceOptions,
      type: TypesOfInput.SELECT,
      isMulti: true,
    },
    {
      label: "Color",
      name: "color",
      placeholder: "Color",
      options: brandColorOptions,
      type: TypesOfInput.SELECT,
    },
    {
      label: "Day report",
      name: "dayReport",
      placeholder: "Day report",
      options: weekDayOptions,
      type: TypesOfInput.SELECT,
    },
    {
      label: "Localizations",
      name: "localizations",
      type: TypesOfInput.LOCALIZATIONS,
    },
    {
      async: asyncAffiliateUsers,
      labelName: "name",
      label: "Affiliate manager",
      name: "affiliateManager",
      options: affiliateUserOptions,
      type: TypesOfInput.SELECT,
      valueName: "id",
      placeholder: "Affiliate manager",
    },
    {
      async: asyncSaleUsers,
      labelName: "name",
      label: "Sale manager",
      name: "saleManager",
      options: saleUserOptions,
      type: TypesOfInput.SELECT,
      valueName: "id",
      placeholder: "Sale manager",
    },
  ]
);
