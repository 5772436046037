import React, { ChangeEvent, useEffect, useState, useMemo } from "react";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { clientsSlice } from "../../store/reducers/clientsSlice";
import { Button, FormControl } from "react-bootstrap";
import FilterForm from "../../components/common/form/formik/FilterForm";
import { fetchClients } from "../../store/thunks/clients";
import { IFilterClient, emptyFilters } from "../../models/IClients";
import { clientFilters } from "../../components/fields/filters-client";
import ClientsTable from "../../components/clients/ClientsTable";
import { Link } from "react-router-dom";
import Actions from "./Actions";
import useDebounce from "../../hooks/useDebounce";
import { TypesOfInput } from "../../models/fields/IField";
import { fetchLabels } from "../../store/thunks/labels";
import { FetchOptionsParams } from "../../types/global";

const Clients = () => {
  const dispatch = useAppDispatch();
  const { filter, searchTerm, clients } = useAppSelector((state) => state.clientsReducer);
  const { setFilters, setSearchTerm } = clientsSlice.actions;
  const [search, setSearch] = useState(searchTerm);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const debouncedSearch = useDebounce<string>(search, 500);

  const { labels } = useAppSelector((state) => state.labelsReducer);

  useEffect(() => {
    if (!clients.data.length) {
      dispatch(fetchClients({}));
    }
    return () => {
      dispatch(setFilters(emptyFilters));
      dispatch(setSearchTerm(''));
    };
  }, []);

  useEffect(() => {
    if (!clients.data.length && !debouncedSearch) {
      return;
    }
    dispatch(setSearchTerm(search));
    dispatch(fetchClients({ searchTerm: debouncedSearch }));
  }, [debouncedSearch]);

  const handleFilter = (values: IFilterClient) => {
    dispatch(setFilters(values));
    return dispatch(fetchClients({ searchTerm: debouncedSearch }));
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const fields = useMemo(
    () =>
      clientFilters({
        asyncLabels: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchLabels(params)),
          isLoading: labels.loading,
        },
        labelOptions: labels.data,
      }),
    [labels],
  );

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title={"Clients"} />
      <div className="row">
        <div className="row d-flex mb-3 justify-between">
          <div className="col-sm-12 col-md-6 d-flex">
            <Button variant="primary" className="mb-2" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
              {isFilterOpen ? "Close" : "Open"} Filters
            </Button>
            <Link className="btn btn-primary mb-2" to="/clients/new">
              Create Client
            </Link>
            <Actions />
          </div>
          <div className="col-sm-6 col-md-3 d-flex align-items-center mt-2">
            <span className="mr-3">Search:</span>
            <FormControl type={TypesOfInput.TEXT} onChange={handleChangeSearch} value={search} />
          </div>
        </div>
        {isFilterOpen && (
          <FilterForm
            className="mb-3"
            fields={fields}
            handleSubmit={handleFilter}
            initialValues={filter}
          />
        )}
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <ClientsTable searchTerm={debouncedSearch} />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Clients;
