import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { advertisementsApi } from "../../api/advertisements-api";
import { dashboardApi } from "../../api/dashboard-api";
import { statisticsClientsApi } from "../../api/statistics-clients-api";
import { statisticsReplacementsApi } from "../../api/statistics-replacements-api";
import { statisticsReportsApi } from "../../api/statistics-reports-api";
import affiliatesReducer from "./affiliatesSlice";
import applicationsSlice from "./applicationsSlice";
import authSlice from "./authSlice";
import clientsReducer from "./clientsSlice";
import countriesSlice from "./countriesSlice";
import dictionaryWordsReducer from "./dictionaryWordsSlice";
import integrationsReducer from "./integrationsSlice";
import labelsReducer from "./labelsSlice";
import leadsReducer from "./leadsSlice";
import localizationsReducer from "./localizationsSlice";
import logsReducer from "./logsSlice";
import offersReducer from "./offersSlice";
import playersSlice from "./playersSlice";
import pushesSlice from "./pushesSlice";
import reviewsReducer from "./reviewsSlice";
import settingsSlice from "./settingsSlice";
import statusesSlice from "./statusesSlice";
import tagsReducer from "./tagsSlice";
import teamsSlice from "./teamsSlice";
import telegramChatsReducer from "./telegramChatsSlice";
import transactionsReducer from "./transactionsSlice";
import userSettingsSlice from "./userSettingsSlice";
import usersReducer from "./usersSlice";
import brandsSlice from "./brandsSlice";
import financesSlice from "./financesSlice";

const reducers = {
  authSlice,
  localizationsReducer,
  leadsReducer,
  affiliatesReducer,
  clientsReducer,
  transactionsReducer,
  telegramChatsReducer,
  dictionaryWordsReducer,
  usersReducer,
  integrationsReducer,
  logsReducer,
  labelsReducer,
  reviewsReducer,
  tagsReducer,
  offersReducer,
  countriesSlice,
  pushesSlice,
  statusesSlice,
  playersSlice,
  teamsSlice,
  settingsSlice,
  userSettingsSlice,
  applicationsSlice,
  brandsSlice,
  financesSlice,
  [statisticsReportsApi.reducerPath]: statisticsReportsApi.reducer,
  [statisticsReplacementsApi.reducerPath]: statisticsReplacementsApi.reducer,
  [statisticsClientsApi.reducerPath]: statisticsClientsApi.reducer,
  [advertisementsApi.reducerPath]: advertisementsApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
};

const rootReducer = combineReducers(reducers);

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, }).concat(
      statisticsReportsApi.middleware,
      advertisementsApi.middleware,
      statisticsReplacementsApi.middleware,
      statisticsClientsApi.middleware,
      dashboardApi.middleware,
    ),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
