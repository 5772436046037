import React, { useEffect, useState } from "react";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { localizationsSlice } from "../../store/reducers/localizationsSlice";
import { Button } from "react-bootstrap";
import FilterForm from "../../components/common/form/formik/FilterForm";
import { fetchLocalizations } from "../../store/thunks/localizations";
import { IFilterLocalization, initialLocalizationFilters } from "../../models/ILocalizations";
import { localizationFilters } from "../../components/fields/filters-localization";
import LocalizationsTable from "../../components/localizations/LocalizationsTable";
import { Link } from "react-router-dom";

const Localizations = () => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector((state) => state.localizationsReducer);
  const { setFilters } = localizationsSlice.actions;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchLocalizations({}));
    return () => {dispatch(setFilters(initialLocalizationFilters))}
  }, []);

  const handleFilter = (values: IFilterLocalization) => {
    dispatch(setFilters(values));
    return dispatch(fetchLocalizations({}));
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title={"Localizations"} />
      <div className="row">
        <div className="d-flex mb-3">
          <Button variant="primary" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
            {isFilterOpen ? "Close" : "Open"} Filters
          </Button>
          <Link className="btn btn-primary" to="/localizations/new">
            Create Localization
          </Link>
        </div>
        {isFilterOpen && (
          <FilterForm
            className="mb-3"
            fields={localizationFilters()}
            handleSubmit={handleFilter}
            initialValues={filter}
          />
        )}
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <LocalizationsTable className="min-w-screen-lg" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Localizations;
