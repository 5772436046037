import React from "react";
import { Link } from "react-router-dom";
import { brandColorOptions, IBrand } from "../../models/IBrand";
import { formatDateFullTime } from "../../utils/formats";
import { ColumnDef, PaginationType } from "../common/tables/types";
import ColorLabel from "../common/items/ColorLabel";
import countriesJson from "../../countries.json";
import { ICountry } from "../../models/ILocalizations";
import { LabelColors } from "../../types/global";

type IColumn = ColumnDef<IBrand>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefs({ pagination }: Options): IColumn[] {
  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const name: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/brands/${cell.row.original.id}`}>
        <div className="d-flex">
          <ColorLabel
            className="d-inline fa-pull-right mr-3"
            colors={brandColorOptions}
            value={cell.row.original.color ?? LabelColors.GRAY}
          />
          {cell.value}
        </div>
      </Link>
    ),
    Header: "Name",
    id: "name",
    accessor: "name",
    width: "120px",
  };

  const type: IColumn = {
    Header: "Type",
    accessor: "type",
    id: "type",
    width: "60px",
  };

  const localizations: IColumn = {
    Cell: ({ value }) => {
      const countries: ICountry[] = countriesJson;
      const flags = new Set(
        value?.map(
          (item) => countries.find((country) => country.code === item.country.toLowerCase())?.flag,
        ),
      );
      return <span>{Array.from(flags).join(" ")}</span>;
    },
    Header: "Localizations",
    id: "localizations",
    accessor: "localizations",
    width: "100px",
  };

  const services: IColumn = {
    Cell: ({ value }) => (
      <span>{value.join(", ")}</span>
    ),
    Header: "Services",
    accessor: "services",
    id: "services",
  };

  const saleManager: IColumn = {
    Cell: (cell) => (
      <span>
        {cell.value ? (
          <Link className="font-semibold" to={`/users/${cell.value.id}`}>
            {cell.value.name}
          </Link>
        ) : (
          "No"
        )}
      </span>
    ),
    Header: "Sale manager",
    accessor: "saleManager",
    id: "saleManager",
    width: "80px",
  };

  const affiliateManager: IColumn = {
    Cell: (cell) => (
      <span>
        {cell.value ? (
          <Link className="font-semibold" to={`/users/${cell.value.id}`}>
            {cell.value.name}
          </Link>
        ) : (
          "No"
        )}
      </span>
    ),
    Header: "Affiliate manager",
    accessor: "affiliateManager",
    id: "affiliateManager",
    width: "100px",
  };

  const dayReport: IColumn = {
    Header: "Day report",
    accessor: "dayReport",
    id: "dayReport",
    width: "80px",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    accessor: "createdAt",
    width: "80px",
    id: "createdAt",
  };

  return [numberIndex, name, type, localizations, services, saleManager, affiliateManager, dayReport, createdAt];
}
