import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../../../models/fields/IField";
import { IFinanceCreate, IFinanceType } from "../../../models/IFinances";
import { AsyncObject } from "../../../models/fields/ISelect";
import { IAffiliate } from "../../../models/IAffiliates";

export const createFinanceValidation = Yup.object<Record<keyof IFinanceCreate, Yup.AnySchema>>({
  affiliate: Yup.object().typeError("Required"),
  type: Yup.object().typeError("Required"),
  spend: Yup.number().integer().required("Required").min(1, 'At least 1'),
  date: Yup.array().of(Yup.string()),
});

export interface Params {
  asyncAffiliates: AsyncObject,
  affiliateOptions: IAffiliate[],
  asyncFinanceType: AsyncObject,
  financeTypeOptions: IFinanceType[],
}

export const createFields = ({
  asyncAffiliates,
  affiliateOptions,
  asyncFinanceType,
  financeTypeOptions,
}: Params): IAllFields<IFinanceCreate>[] => (
  [
    {
      async: asyncAffiliates,
      label: "Affiliate *",
      labelName: "name",
      name: "affiliate",
      options: affiliateOptions,
      placeholder: "Affiliate",
      type: TypesOfInput.SELECT,
      valueName: "id",
      formatOptionLabel: (option: IAffiliate) => <span>{ option.name } - { option.affiliate } </span>,
    },
    {
      async: asyncFinanceType,
      label: "Type *",
      labelName: "name",
      name: "type",
      options: financeTypeOptions,
      placeholder: "Type",
      type: TypesOfInput.SELECT,
      valueName: "id",
    },
    {
      label: "Spend *",
      name: "spend",
      placeholder: "spend",
      type: TypesOfInput.TEXT,
    },

    {
      label: "Date *",
      name: "date",
      placeholder: "Date start/end",
      type: TypesOfInput.DATEPICKER_RANGE,
    },
  ]
);
